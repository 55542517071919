
<template>
    
    <div>
       <v-checkbox v-model="question.multiple" label="Multiple"></v-checkbox>
    </div>
</template>

<script>
export default {
    name: "Tiles", 
    components: {
    }, 
    props: {
        id: { type: String } // the id of hte question template
    }, 
    computed:{ 
        question(){
            return this.$store.state.questions.data[this.id] || {}
        }
    }, 

}
</script>
